import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject, take, takeUntil, timer } from 'rxjs';
import { InStockItem } from 'shared';
import { InventoryService } from './../../pages/inventory/inventory.service';

@Component({
  selector: 'app-search-inventory',
  templateUrl: './search-inventory.component.html',
  styleUrls: ['./search-inventory.component.scss']
})
export class SearchInventoryComponent implements OnInit {
  // function to execute
  @Input() searchFn: (search: string) => Observable<InStockItem[]> =
    this.inventoryService.getInStockItems;
  // input from parent to cache while searching
  @Input() cache: InStockItem[] = [];
  // results from search
  @Output() results = new EventEmitter<InStockItem[]>();
  // status events
  @Output() searching = new EventEmitter<boolean>();
  @Output() end = new EventEmitter<boolean>();

  tmp: InStockItem[] = [];

  queryString = '';

  clearTimer$ = new Subject();

  constructor(private inventoryService: InventoryService) {}

  ngOnInit() {}

  async makeSearch() {
    if (this.queryString === '') {
      this.results.emit(Array.from(this.tmp));
      this.tmp = [];
      this.end.emit(true);
    } else {
      if (this.tmp.length < 1) {
        this.tmp = Array.from(this.cache);
      }
      this.clearTimer$.next(true);
      this.searching.emit(true);
      timer(1000)
        .pipe(takeUntil(this.clearTimer$))
        .subscribe(async () => {
          this.results.emit(
            await this.inventoryService[this.searchFn.name](this.queryString)
              .pipe(take(1))
              .toPromise()
          );
        });
    }
  }
}
