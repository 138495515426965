export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyDc-GTDymRBhw1YNSEuGNepIQVedn9gaB8',
    authDomain: 'russells-web.firebaseapp.com',
    databaseURL: 'https://russells-web.firebaseio.com',
    projectId: 'russells-web',
    storageBucket: 'russells-web.appspot.com',
    messagingSenderId: '700413573653',
    appId: '1:700413573653:web:cdd91a1d3e6fe3452f6c1c',
    measurementId: 'G-5QKC7DHCS8'
  }
};
