import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cms';

  constructor(public themeService: NbThemeService, private analyticsServcie: AngularFireAnalytics) {
    this.analyticsServcie.setAnalyticsCollectionEnabled(true);
    const theme = localStorage.getItem('theme') || 'dark';
    this.themeService.changeTheme(theme);
  }

  async ngOnInit(): Promise<void> {}
}
