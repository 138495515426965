import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { CatalogRecord, Doc, InStockItem } from 'shared';
import { initInStockItemRecord } from 'shared/dist/inventory/in-stock-item';
import { DbService } from './../../services/db.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  constructor(private db: DbService) {}

  createInStockItem(record: CatalogRecord, id?: string): Promise<InStockItem> {
    const item = this.convertCatalogToInStockItem(record);
    return this.db.create<InStockItem>(item, id);
  }

  deleteInStockItem(record: InStockItem): Promise<void> {
    return this.db.delete<InStockItem>(record);
  }

  getInStockItems(
    searchTerm: string = '',
    startAfter: any = '',
    limit = 5
  ): Observable<InStockItem[]> {
    let queryFn;
    queryFn = (ref) => {
      if (searchTerm) {
        ref = ref.where('searchTerms', 'array-contains', searchTerm.toLowerCase());
      }
      ref = ref.orderBy('createdAt', 'asc');
      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }
      return ref.limit(limit);
    };
    return this.db.queryGet$<InStockItem>('in-stock-items', queryFn, initInStockItemRecord);
  }

  async batchRenewItems(since?: Date, until: Date = new Date()) {
    let queryFn;
    queryFn = (ref) => {
      ref = ref.where('createdAt', '>=', since);
      ref = ref.where('createdAt', '<=', until);
      return ref;
    };
    const res = await firstValueFrom(
      this.db.queryGet$<InStockItem>('in-stock-items', queryFn, initInStockItemRecord)
    );
    const docs: Doc[] = res.map((r) => {
      return this.convertCatalogToInStockItem(r);
    });
    // break up batches
    let count = 0;
    let batch = [];
    for (const doc of docs) {
      if (count % 100 === 0) {
        await this.db.batchUpdate(batch);
        batch = [];
      }
      batch.push(doc);
      count++;
    }
    return this.db.batchUpdate(batch);
  }

  private convertCatalogToInStockItem(record: CatalogRecord | InStockItem): InStockItem {
    delete record.searchTerms;
    delete record.collection;
    // delete record.wholesalePrice;
    // delete record.retailPrice;
    delete record.updatedAt;
    delete record.updatedBy;
    delete record.createdAt;
    delete record.createdBy;
    // fix name for alphabetizing
    let sizing = '';
    let name = '';
    const split = record.name.split(' ');
    split.forEach((seg) => {
      if (seg.length > 0 && /[a-zA-Z]/.test(seg.charAt(0))) {
        name += seg + ' ';
      } else {
        sizing += seg + ' ';
      }
    });
    record.name = (name + sizing).trim();
    return initInStockItemRecord(record);
  }
}
