import { takeUntil, take } from 'rxjs/operators';
import { CatalogService } from './../../pages/catalog/catalog.service';
import { Observable, Subject, timer } from 'rxjs';
import { CatalogRecord } from 'shared';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-catalog',
  templateUrl: './search-catalog.component.html',
  styleUrls: ['./search-catalog.component.scss']
})
export class SearchCatalogComponent implements OnInit {
  // function to execute
  @Input() searchFn: (search: string) => Observable<CatalogRecord[]> =
    this.catalogService.getCatalogRecords;
  // input from parent to cache while searching
  @Input() cache: CatalogRecord[] = [];
  // results from search
  @Output() results = new EventEmitter<CatalogRecord[]>();
  // status events
  @Output() searching = new EventEmitter<boolean>();
  @Output() end = new EventEmitter<boolean>();

  tmp: CatalogRecord[] = [];

  queryString = '';

  clearTimer$ = new Subject();

  constructor(private catalogService: CatalogService) {}

  ngOnInit() {}

  async makeSearch() {
    if (this.queryString === '') {
      this.results.emit(Array.from(this.tmp));
      this.tmp = [];
      this.end.emit(true);
    } else {
      if (this.tmp.length < 1) {
        this.tmp = Array.from(this.cache);
      }
      this.clearTimer$.next(true);
      this.searching.emit(true);
      timer(1000)
        .pipe(takeUntil(this.clearTimer$))
        .subscribe(async () => {
          this.results.emit(
            await this.catalogService[this.searchFn.name](this.queryString)
              .pipe(take(1))
              .toPromise()
          );
        });
    }
  }
}
