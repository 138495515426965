import { Account } from 'shared';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $user = this.afAuth.authState;

  constructor(private afAuth: AngularFireAuth) {}

  async createUserWithEmailAndPassword(account: Account, password: string) {
    await this.afAuth.createUserWithEmailAndPassword(account.email, password);
    const currentUser = await this.afAuth.currentUser;
    await currentUser.sendEmailVerification();
    return currentUser.updateProfile({
      displayName: account.displayName
    });
  }

  async sendEmailVerification() {
    const currentUser = await this.afAuth.currentUser;
    return currentUser.sendEmailVerification();
  }

  loginWithEmail(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.afAuth.signOut();
  }

  requestEmailPasswordLink(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async refreshUser() {
    const currentUser = await this.afAuth.currentUser;
    if (currentUser) {
      await currentUser.getIdToken(true);
      await currentUser.reload();
    }
  }

  async getIdTokenResult(refresh?: boolean) {
    const currentUser = await this.afAuth.currentUser;
    return currentUser.getIdTokenResult(refresh);
  }

  async getUserRoles(): Promise<string[]> {
    const token = await this.getIdTokenResult(true);
    return (token.claims.roles as string[]) || [];
  }

  async userHasRole(roleName: string): Promise<boolean> {
    return (await this.getUserRoles()).some((r) => {
      return r === roleName;
    });
  }

  getCMSRoleOptions() {
    return ['admin', 'inventory-admin', 'shop-admin', 'cms-user', 'design-admin'];
  }
}
