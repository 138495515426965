<nb-layout windowMode>
  <nb-layout-header fixed>
    <div class="nav-wrapper">
      <div class="header-container">
        <div class="logo-container">
          <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
          </a>
          <a class="logo" href="#">Russell's Portal</a>
        </div>
        <nb-select
          [selected]="currentTheme"
          (selectedChange)="themeService.changeTheme($event); saveThemePreference($event)"
          status="primary"
          *ngIf="!(layoutService.$isHandset | async)"
        >
          <nb-option *ngFor="let theme of themes" [value]="theme.value">
            {{ theme.name }}</nb-option
          >
        </nb-select>
      </div>
      <div class="header-container">
        <nb-actions size="tiny">
          <nb-action class="user-action">
            <nb-user
              *ngIf="authService.$user | async as user"
              [onlyPicture]="false"
              [title]="claims.roles[0]"
              [name]="user.displayName"
              [picture]="user.photoURL"
              [nbContextMenu]="userMenu"
            >
            </nb-user>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-layout-header>
  <nb-sidebar tag="menu-sidebar" responsive compactedBreakpoints="[]" #sidebar>
    <nb-menu [items]="menu"></nb-menu>
    <label *ngIf="layoutService.$isHandset | async" class="theme-padding">
      <strong>Theme: </strong>
    </label>
    <nb-select
      [selected]="currentTheme"
      (selectedChange)="themeService.changeTheme($event); saveThemePreference($event)"
      status="primary"
      *ngIf="layoutService.$isHandset | async"
    >
      <nb-option *ngFor="let theme of themes" [value]="theme.value">{{ theme.name }}</nb-option>
    </nb-select>
  </nb-sidebar>

  <nb-layout-column>
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>

  <!-- <nb-layout-footer fixed>
    Footer
  </nb-layout-footer> -->
</nb-layout>
