<div
  class="dropzone"
  appDropzone
  (hovered)="toggleHover($event)"
  (dropped)="onDrop($event)"
  [class.hovering]="isHovering"
  *ngIf="(!multiple && files.length < 1) || multiple"
>
  <p>Drag n' Drop Files</p>
  <input
    class="inputfile"
    type="file"
    [name]="divId"
    [id]="divId"
    accept=".jpg,.jpeg,.png,image/*,.pdf,.csv"
    (change)="onDrop($event?.target?.files)"
    [multiple]="multiple"
  />
  <label [for]="divId">Choose a file</label>
</div>
<nb-alert class="text-center" *ngIf="files.length > 0" status="warning"
  >Pending Uploads: {{ files.length }}</nb-alert
>
<button class="text-center" nbButton status="warning" *ngIf="files.length > 0" (click)="files = []">
  Reset Uploads
</button>
