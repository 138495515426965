import { take } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { DbService } from './../../services/db.service';
import { Injectable } from '@angular/core';
import { CatalogRecord, initCatalogRecord, prepareCatalogRecord } from 'shared';
import { GalleryRecord } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  constructor(private dbService: DbService, private functions: AngularFireFunctions) {}

  createCatalogRecord(record: CatalogRecord): Promise<CatalogRecord> {
    return this.dbService.create<CatalogRecord>(prepareCatalogRecord(record), record.id);
  }

  getCatalogRecord(id: string): Promise<CatalogRecord> {
    return this.dbService.read(id, 'catalog-records');
  }

  batchCreateCatalogRecords(records: CatalogRecord[]): Promise<boolean> {
    return this.dbService.batchCreate(
      records.map((record) => {
        return prepareCatalogRecord(record);
      })
    );
  }

  batchUpdateCatalogRecords(records): Promise<void> {
    return this.dbService.batchUpdate(
      records.map((record) => {
        return prepareCatalogRecord(record, true);
      })
    );
  }

  setCatalogRecordPrices(retail, wholesale, resetWholesaleCatalogFlag = false): Promise<number> {
    return this.functions
      .httpsCallable<any, number>('resetCatalogPrices', { timeout: 300000 })({
        retail,
        wholesale,
        resetWholesaleCatalogFlag
      })
      .pipe(take(1))
      .toPromise();
  }

  updateCatalogRecordsBulk(filePath: string, dryRun: boolean): Promise<{ [key: string]: number }> {
    return this.functions
      .httpsCallable<any, { [key: string]: number }>('updateCatalogRecordsBulk', {
        timeout: 300000
      })({
        filePath,
        dryRun
      })
      .pipe(take(1))
      .toPromise();
  }

  exportCatalogRecordsToCSV() {
    return this.functions
      .httpsCallable<any, any>('exportCollection')({
        collection: 'catalog-records'
      })
      .pipe(take(1))
      .toPromise();
  }

  updateCatalogRecord(record: CatalogRecord): Promise<CatalogRecord> {
    return this.dbService.update<CatalogRecord>(prepareCatalogRecord(record));
  }

  deleteCatalogRecord(record: CatalogRecord): Promise<void> {
    return this.dbService.delete<CatalogRecord>(record);
  }

  getCatalogRecords(searchTerm: string = '', startAfter: any = ''): Observable<CatalogRecord[]> {
    let queryFn;
    queryFn = (ref) => {
      ref = ref.orderBy('id', 'asc');
      if (searchTerm) {
        ref = ref.where('searchTerms', 'array-contains', searchTerm.toLowerCase());
      }
      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }
      return ref.limit(10);
    };
    return this.dbService.query$<CatalogRecord>('catalog-records', queryFn, initCatalogRecord);
  }

  getAllCatalogRecords(): Observable<CatalogRecord[]> {
    return this.dbService.query$<CatalogRecord>('catalog-records', undefined, initCatalogRecord);
  }

  getGalleryCatalogRecords(galleryRecordId: string) {
    return this.dbService.query$<CatalogRecord>(
      'catalog-records',
      (ref) => {
        return ref.where('galleryRecordId', '==', galleryRecordId).limit(20);
      },
      initCatalogRecord
    );
  }

  async linkCatalogRecordToGallery(catalogRecord: CatalogRecord, galleryRecord: GalleryRecord) {
    const catalogId = catalogRecord.id;
    galleryRecord.catalogRecords.push(catalogId);
    if (catalogRecord.inCatalog) {
      galleryRecord.activeCatalogRecords.push(catalogId);
    }
    galleryRecord.searchTerms.push(catalogId);

    catalogRecord.galleryRecordId = galleryRecord.id;
    catalogRecord.category = galleryRecord.category;
    catalogRecord.categorySearch = galleryRecord.categorySearch;

    const catalogRef = this.dbService.db.collection('catalog-records').doc(catalogId).ref;
    const galleryRef = this.dbService.db.collection('gallery-records').doc(galleryRecord.id).ref;

    return this.dbService.db.firestore.runTransaction(async (transaction) => {
      transaction.update(catalogRef, catalogRecord);
      transaction.update(galleryRef, galleryRecord);
    });
  }
}
