import { DropzoneDirective } from './directives/dropzone/dropzone.directive';
import { LayoutService } from './../services/layout.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbLayoutModule,
  NbSidebarModule,
  NbButtonModule,
  NbToggleModule,
  NbActionsModule,
  NbUserModule,
  NbSelectModule,
  NbIconModule,
  NbMenuModule,
  NbContextMenuModule,
  NbAlertModule,
  NbInputModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ShellComponent } from './shell/shell.component';
import { DeleteButtonComponent } from './delete-button/delete-button.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SearchGalleryComponent } from './search-gallery/search-gallery.component';
import { FormsModule } from '@angular/forms';
import { DocScrollerComponent } from './doc-scroller/doc-scroller.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchCatalogComponent } from './search-catalog/search-catalog.component';
import { SearchInventoryComponent } from './search-inventory/search-inventory.component';

const components = [
  ShellComponent,
  DeleteButtonComponent,
  FileUploadComponent,
  DropzoneDirective,
  DocScrollerComponent,
  SearchCatalogComponent,
  SearchGalleryComponent,
  SearchInventoryComponent
];
const modules = [
  CommonModule,
  ScrollingModule,
  FormsModule,
  NbLayoutModule,
  NbIconModule,
  NbUserModule,
  NbActionsModule,
  NbMenuModule,
  NbEvaIconsModule,
  NbInputModule,
  NbToggleModule,
  NbActionsModule,
  NbButtonModule,
  NbSidebarModule,
  NbSelectModule,
  NbAlertModule,
  NbContextMenuModule
];

@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components],
  providers: [LayoutService]
})
export class SharedModule {}
