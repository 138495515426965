import { takeUntil, take } from 'rxjs/operators';
import { AuthService } from './../../auth/auth.service';
import { MENU_ITEMS } from './../../pages/pages-menu';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  NbThemeService,
  NbSidebarService,
  NbMenuItem,
  NbMenuService,
  NbSidebarComponent
} from '@nebular/theme';
import { LayoutService } from './../../services/layout.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  @ViewChild(NbSidebarComponent) sidebar: NbSidebarComponent;
  themes = [
    {
      value: 'default',
      name: 'Light'
    },
    {
      value: 'dark',
      name: 'Dark'
    }
  ];

  currentTheme = 'default';
  menu = [];
  userMenu: NbMenuItem[] = [{ title: 'Logout', link: '/auth/logout' }];
  claims: {
    [key: string]: any;
  } = { roles: [] };
  $destroy = new Subject<boolean>();

  // admin is defaulted
  menuItemRoles = {
    Home: ['cms-user'],
    Analytics: [],
    Catalog: [],
    Design: ['design-admin'],
    Gallery: [],
    Inventory: ['inventory-admin', 'cms-user'],
    Operations: ['cms-user'],
    Shop: ['shop-admin'],
    Retail: [],
    Wholesale: [],
    Settings: []
  };
  constructor(
    public themeService: NbThemeService,
    public sidebarService: NbSidebarService,
    public layoutService: LayoutService,
    public authService: AuthService,
    private menuService: NbMenuService
  ) {}

  async ngOnInit() {
    this.claims = (await this.authService.getIdTokenResult()).claims;
    this.menuService
      .onItemSelect()
      .pipe(takeUntil(this.$destroy))
      .subscribe(async () => {
        const isHandset = await this.layoutService.$isHandset.pipe(take(1)).toPromise();
        if (isHandset) {
          await this.sidebar.collapse();
        }
      });
    MENU_ITEMS.forEach((m, index: number) => {
      if (this.claims.roles.indexOf('admin') > -1) {
        this.menu.push(m);
        return;
      }
      const keep = this.menuItemRoles[m.title]?.some((approvedRole) => {
        return this.claims.roles.indexOf(approvedRole) > -1;
      });
      if (keep) {
        this.menu.push(m);
      }
    });
  }

  async ngOnDestroy() {
    this.$destroy.next(true);
  }

  saveThemePreference(themeName: string) {
    localStorage.setItem('theme', themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
}
