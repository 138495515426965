import {
  AngularFireRemoteConfig,
  AngularFireRemoteConfigModule,
  SETTINGS
} from '@angular/fire/compat/remote-config';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  Injectable,
  ErrorHandler,
  Injector,
  APP_INITIALIZER,
  inject
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import {
  NbThemeModule,
  NbMenuModule,
  NbSidebarModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbToastrModule,
  NbToastrService,
  NbTimepickerModule
} from '@nebular/theme';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService
} from '@angular/fire/compat/performance';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/compat/analytics';

import { SharedModule } from './shared/shared.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbAuthModule } from '@nebular/auth';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AuthService } from './auth/auth.service';
import { take } from 'rxjs';

declare let newrelic;
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  async handleError(error: Error) {
    const authService = this.injector.get(AuthService);
    if (
      (error?.name !== 'FirebaseError' && error?.message?.indexOf('FirebaseError') < 0) ||
      error?.message?.indexOf('code=permission-denied') > -1
    ) {
      const toastService = this.injector.get(NbToastrService);
      const message = error.message;
      console.log(message);
      toastService.danger(message, 'We hit a glitch');
    }
    console.error('Global Error Handler', error);
    const user = await authService.$user.pipe(take(1)).toPromise();
    newrelic.noticeError(error, { globalHandler: true, uid: user.uid });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirePerformanceModule,
    AngularFireRemoteConfigModule,
    AngularFireFunctionsModule,
    AngularFireAnalyticsModule,
    BrowserModule,
    ScrollingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbAuthModule.forRoot(),
    NbEvaIconsModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: SETTINGS,
      useValue: { minimumFetchIntervalMillis: environment.production ? 5_000 : 5_000 }
    },
    // The APP_INITIALIZER multi-provider allows for additional asynchronous
    // configuration to take place during the bootstrapping process. These
    // initializers will "block" the application until the returned Promises are
    // resolved, which allows us to make a request to the remote server in order to
    // gather user-specific or environment-specific configuration values that are NOT
    // available at compile-time.
    {
      provide: APP_INITIALIZER,
      useFactory: (remoteConfig: AngularFireRemoteConfig): (() => Promise<void>) => {
        async function asyncInitializer(): Promise<void> {
          // The .loadRemoteConfig() method will trigger an AJAX request that
          // will populate the AppConfig instance. This way, it will contain
          // the remote configuration data by the time that it gets injected
          // into other Providers and Classes.
          try {
            await remoteConfig.ensureInitialized();
            await remoteConfig.fetchAndActivate();
          } catch (error) {
            console.log('Issue starting remote config', error);
          }
        }

        // NOTE: The factory function returns the asynchronous FUNCTION - it does
        // not execute the function directly.
        return asyncInitializer;
      },
      deps: [AngularFireRemoteConfig],
      multi: true
    },
    PerformanceMonitoringService,
    UserTrackingService,
    ScreenTrackingService,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: true,
        allow_ad_personalization_signals: true,
        anonymize_ip: false
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
