import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NbToastrService } from '@nebular/theme';
import { take } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private toastrService: NbToastrService,
    private router: Router,
    private authService: AuthService,
    private analyticsService: AngularFireAnalytics
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.afAuth.authState.pipe(take(1)).toPromise();
    const token = (await this.afAuth.currentUser)
      ? await (await this.afAuth.currentUser).getIdTokenResult(true)
      : {
          claims: {
            roles: []
          }
        };
    const roles: string[] = token.claims.roles || [];
    let isApprovedRole = false;
    const availRoles = this.authService.getCMSRoleOptions();
    roles.forEach((r) => {
      if (availRoles.indexOf(r) > -1) {
        isApprovedRole = true;
      }
    });
    const isLoggedIn = !!user && user.emailVerified && isApprovedRole;
    if (!isLoggedIn) {
      await this.router.navigate(['/auth']);
      this.toastrService.danger(
        'Unable to access',
        'Must be logged in to view this content. Make sure to verify your email address.',
        {
          destroyByClick: true,
          duration: 10000
        }
      );
      if (!isApprovedRole) {
        this.toastrService.warning('CMS Prohibited', 'Must be an admin to view this content', {
          destroyByClick: true,
          duration: 10000
        });
      }
    } else {
      this.analyticsService.setUserId(user.uid);
    }
    return isLoggedIn;
  }
}
