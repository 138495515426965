import { PhotoGalleryService } from './../../pages/photo-gallery/photo-gallery.service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GalleryRecord } from 'shared';
import { take, takeUntil } from 'rxjs/operators';
import { timer, Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-search-gallery',
  templateUrl: './search-gallery.component.html',
  styleUrls: ['./search-gallery.component.scss']
})
export class SearchGalleryComponent implements OnInit {
  // function to execute
  @Input() searchFn: (search: string) => Observable<GalleryRecord[]>;
  // input from parent to cache while searching
  @Input() cache: GalleryRecord[];
  // results from search
  @Output() results = new EventEmitter<GalleryRecord[]>();
  // status events
  @Output() searching = new EventEmitter<boolean>();
  @Output() end = new EventEmitter<boolean>();

  tmp: GalleryRecord[] = [];

  queryString = '';

  clearTimer$ = new Subject();

  constructor(private galleryService: PhotoGalleryService) {}

  ngOnInit() {}

  async makeSearch() {
    if (this.queryString === '') {
      this.results.emit(Array.from(this.tmp));
      this.tmp = [];
      this.end.emit(true);
    } else {
      if (this.tmp.length < 1) {
        this.tmp = Array.from(this.cache);
      }
      this.clearTimer$.next(true);
      this.searching.emit(true);
      timer(1000)
        .pipe(takeUntil(this.clearTimer$))
        .subscribe(async () => {
          const result = await this.galleryService[this.searchFn.name](this.queryString)
            .pipe(take(1))
            .toPromise();
          console.log(result);
          this.results.emit(result);
        });
    }
  }
}
