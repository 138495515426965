import { mergeMap, map, scan, tap } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ContentChild,
  TemplateRef
} from '@angular/core';
import { Doc } from 'shared';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export interface ScrollerQuery {
  service: any;
  serviceFunction: string;
  cursorKey: string;
}

@Component({
  selector: 'app-doc-scroller',
  templateUrl: './doc-scroller.component.html',
  styleUrls: ['./doc-scroller.component.scss']
})
export class DocScrollerComponent implements OnInit {
  @ContentChild(TemplateRef, { static: true })
  child;
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  // state and data
  docs: Doc[] = [];
  docCount = 0;
  isEnd = false;

  infinite$: Observable<any[]>;
  private trigger = new BehaviorSubject(undefined);
  private toDelete: Doc;
  private cursors = new Map<number, Doc>();

  // output for when to send new paged query
  @Output() updateQueries = new EventEmitter<Map<number, Doc>>();

  // inputs
  @Input() recordSize = 75;
  @Input() queryObservables: Observable<any>[] = [];
  @Input() isHorizontal = false;
  @Input() orderByFn: (a: any, b: any) => number; // used if multiple sources are in query obs (i.e. comblatest)
  @Input() filterFn: (a: any, b: any) => boolean;
  @Input() reduceFn: (
    previousValue: any,
    currentValue: any,
    currentIndex: number,
    array: any[]
  ) => any;

  constructor() {}

  ngOnInit() {
    this.fetch();
    this.infinite$ = this.trigger.pipe(
      mergeMap((c) => this.getBatch()),
      scan((acc, batch) => {
        this.isEnd = batch.length < 1;
        if (this.toDelete) {
          if (acc.hasOwnProperty(this.toDelete.id)) {
            delete acc[this.toDelete.id];
            this.viewport?.checkViewportSize();
          }
          this.toDelete = null;
        }
        batch = batch.reduce((accu, cur) => {
          const id = cur.id;
          const data = cur;
          return { ...accu, [id]: data };
        }, {});
        return { ...acc, ...batch };
      }, []),
      map((v) => {
        return Object.values(v);
      }),
      tap((records) => {
        this.docs = records;
        this.docCount = records.length;
      })
    );
  }

  private getBatch() {
    return combineLatest(this.queryObservables).pipe(
      map((results: any[][]) => {
        let values = [];
        results.forEach((res, order) => {
          // update cursor
          if (res.length > 0) {
            this.cursors.set(order, res[res.length - 1]);
          }
          values = [...values, ...res];
        });
        if (this.reduceFn) {
          values = values.reduce(this.reduceFn, []);
        }
        if (this.filterFn) {
          values = values.filter(this.filterFn);
        }
        if (this.orderByFn) {
          values = values.sort(this.orderByFn);
        }
        return values;
      })
    );
  }

  setQueryObservables(queryObservables: Observable<any>[]): void {
    while (this.queryObservables.length > 0) {
      // clear for next query set
      this.queryObservables.pop();
    }
    this.queryObservables.push(...queryObservables);
  }

  fetch() {
    this.trigger.next(true);
  }

  advanceScroller(event) {
    if (this.isEnd) {
      return;
    }
    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      this.updateQueries.next(this.cursors);
    }
  }

  deleteDoc(doc: Doc) {
    if (doc && doc.id) {
      this.toDelete = doc;
      this.viewport?.checkViewportSize();
    }
  }

  pause() {
    this.isEnd = true;
  }

  resume() {
    this.isEnd = false;
  }

  trackByIdx(i, doc) {
    return doc.id;
  }
}
