import { Injectable } from '@angular/core';
import { map, first } from 'rxjs/operators';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Doc, generateDocSearchTerms } from 'shared';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class DbService {
  private authenticatedAccount: firebase.User;

  constructor(private afs: AngularFirestore, private auth: AuthService) {
    this.auth.$user.subscribe((user) => {
      this.authenticatedAccount = user;
    });
  }

  get db() {
    return this.afs;
  }

  get FieldValue() {
    return firebase.firestore.FieldValue;
  }

  getAuthenticatedAccount() {
    return this.authenticatedAccount;
  }

  generateId() {
    return this.afs.createId();
  }

  query$<T extends Doc>(
    collection: string,
    query?: QueryFn,
    initializer?: (doc: T) => T,
    parent?: Doc
  ): Observable<T[]> {
    return this.afs
      .collection<T>(this.prepareParentEndpoint(parent) + collection, query)
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            return initializer ? initializer(a.payload.doc.data()) : a.payload.doc.data();
          });
        })
      );
  }

  queryGet$<T extends Doc>(
    collection: string,
    query?: QueryFn,
    initializer?: (doc: T | any) => T,
    parent?: Doc
  ): Observable<T[]> {
    return this.afs
      .collection<T>(this.prepareParentEndpoint(parent) + collection, query)
      .get()
      .pipe(
        map((actions) => {
          return actions.docs.map((a) => {
            return initializer ? (initializer(a.data()) as T) : (a.data() as T);
          });
        })
      );
  }

  doc$<T extends Doc>(path: string, initializer?: (doc: T) => T, parent?: Doc): Observable<T> {
    return this.afs
      .doc<T>(this.prepareParentEndpoint(parent) + path)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return initializer ? initializer(doc.payload.data()) : doc.payload.data();
        })
      );
  }

  /// C.R.U.D. OPS

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   *
   */
  async create<T extends Doc>(data: T, id?: string): Promise<T> {
    data = this.prepareData(data, id) as T;
    const path = this.getPath(data);
    const segments = path.split('/').filter((v) => v);
    try {
      if (segments.length % 2) {
        // Odd is always a collection
        await this.afs.collection(path).doc(data.id).set(data, { merge: true });
      } else {
        // Even is always document
        await this.afs.doc(path).set(data, { merge: true });
      }
    } catch (error) {
      return Promise.reject(error);
    }

    return {
      ...(data as any),
      ...({
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date())
      } as any)
    };
  }

  read<T extends Doc>(
    key: string,
    collection: string,
    parent?: Doc,
    initializer?: (doc: T) => T
  ): Promise<T> {
    const path = this.prepareParentEndpoint(parent) + collection + '/' + key;
    return this.afs
      .doc<T>(path)
      .valueChanges()
      .pipe(
        map((doc) => {
          return initializer ? initializer(doc) : doc;
        }),
        first()
      )
      .toPromise();
  }

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   *
   */
  async update<T extends Doc>(data: T, merge = true): Promise<T> {
    const path = this.getPath(data);
    const segments = path.split('/').filter((v) => v);
    data = this.prepareData(data, data.id) as T;
    try {
      if (segments.length % 2) {
        // Odd is always a collection
        await this.afs.collection(path).add(data);
      } else {
        // Even is always document
        await this.afs.doc(path).set(data, { merge });
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return data;
  }

  ref<T>(collection: string, path: string): firebase.firestore.DocumentReference<T> {
    return this.afs.collection<T>(collection).doc<T>(path).ref;
  }

  async transaction<T>(
    updateFunction: (transaction: firebase.firestore.Transaction) => Promise<T>
  ): Promise<T> {
    return this.afs.firestore.runTransaction(updateFunction);
  }

  /**
   * @param  {string} path path to document
   *
   * Deletes document from Firestore
   *
   */
  delete<T extends Doc>(doc: T): Promise<void> {
    const path = this.getPath(doc);
    return this.afs.doc(path).delete();
  }

  async batchCreate(refCreate: Doc[]) {
    const batch = this.afs.firestore.batch();

    refCreate.forEach((info: Doc) => {
      const newData = this.prepareData(info, info.id);
      batch.set(this.doc(`${info.collection}/${newData.id}`), newData, {
        merge: true
      });
    });

    try {
      await batch.commit();
    } catch (error) {
      return Promise.reject(error);
    }
    return true;
  }

  async batchUpdate(refCreate: Doc[]) {
    const batch = this.afs.firestore.batch();

    refCreate.forEach((info: Doc) => {
      const newData = {
        ...info,
        ...this.prepareData(info, info.id)
      };
      batch.set(this.doc(`${info.collection}/${newData.id}`), newData, {
        merge: true
      });
    });

    return batch.commit();
  }

  doc(path: string) {
    return this.afs.firestore.doc(path);
  }

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  private prepareParentEndpoint(parent?: Doc): string {
    return parent ? this.getPath(parent) + '/' : '';
  }

  prepareData(data, id?: string): Doc {
    const timestamp = this.timestamp;
    const preppedData = {
      ...(data as any),
      id: id ? id : this.afs.createId(),
      updatedAt: timestamp,
      updatedBy: this.authenticatedAccount.uid
    };
    if (!data.createdBy) {
      preppedData.createdBy = this.authenticatedAccount.uid;
    }
    if (!data.createdAt) {
      preppedData.createdAt = new Date(); // breaks cursor with afs timestamp pending
    }
    return preppedData;
  }

  getPath(doc: Doc): string {
    const path = '/' + doc.collection + '/' + doc.id;
    return doc.parent ? this.getPath(doc.parent) + path : path;
  }

  generateSearchTerms(input: string, extraTerms?: string[]): string[] {
    return generateDocSearchTerms(input, extraTerms);
  }
}
