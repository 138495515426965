import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay, share, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class LayoutService {
  // used to subscribe to whenever layout is changed
  protected layoutSize$ = new Subject();
  $isHandset: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  changeLayoutSize() {
    this.layoutSize$.next(true);
  }

  onChangeLayoutSize(): Observable<any> {
    return this.layoutSize$.pipe(share(), delay(1));
  }
}
