import { Observable } from 'rxjs';
import { DbService } from './../../services/db.service';
import { Injectable } from '@angular/core';
import {
  GalleryRecord,
  initGalleryRecord,
  PlantGalleryRecord,
  initPlantGalleryRecord,
  GALLERY_COLLECTIONS,
  GALLERY_CATEGORIES
} from 'shared';

@Injectable({
  providedIn: 'root'
})
export class PhotoGalleryService {
  constructor(private db: DbService) {}

  get GalleryCollections() {
    return GALLERY_COLLECTIONS;
  }

  get GalleryCategories() {
    return GALLERY_CATEGORIES;
  }

  createGalleryItem(record: GalleryRecord, id?: string): Promise<GalleryRecord> {
    return this.db.create<GalleryRecord>(this.prepareGalleryItem(record), id);
  }

  private prepareGalleryItem(galleryRecord: GalleryRecord) {
    // category
    galleryRecord.category = galleryRecord.category.toLowerCase().trim();
    const categories = galleryRecord.category.split('-');
    galleryRecord.categorySearch = categories;

    let keys;
    let galleryAltName = '';
    // metrics
    if (galleryRecord.galleryCollection === 'plants') {
      keys = Object.keys(initPlantGalleryRecord());
      const plantRecord: PlantGalleryRecord = galleryRecord as PlantGalleryRecord;
      galleryAltName = plantRecord.latin;
      // remove possible invalid character entries
      plantRecord.height = plantRecord.height.replace(`'`, '');
      plantRecord.height = plantRecord.height.replace(`"`, '');
      plantRecord.height = plantRecord.height.trim();
      plantRecord.spread = plantRecord.spread.replace(`'`, '');
      plantRecord.spread = plantRecord.spread.replace(`"`, '');
      plantRecord.spread = plantRecord.spread.trim();
      const heights = plantRecord.height.split('-');
      const spreads = plantRecord.spread.split('-');
      heights.forEach((value) => {
        // convert
        let num = Number(this.numericQuantity(value));
        if (!isNaN(num)) {
          if (plantRecord.heightUnit === 'ft') {
            num = num * 12;
          }
          plantRecord.minHeightInches =
            plantRecord.minHeightInches > 0 ? Math.min(plantRecord.minHeightInches, num) : num;
          plantRecord.avgHeightInches =
            plantRecord.avgHeightInches > 0 ? (plantRecord.avgHeightInches + num) / 2 : num;
          plantRecord.maxHeightInches = Math.max(plantRecord.maxHeightInches, num);
        }
      });
      spreads.forEach((value) => {
        let num = Number(this.numericQuantity(value));
        if (!isNaN(num)) {
          if (plantRecord.spreadUnit === 'ft') {
            num = num * 12;
          }
          plantRecord.minSpreadInches =
            plantRecord.minSpreadInches > 0 ? Math.min(plantRecord.minSpreadInches, num) : num;
          plantRecord.avgSpreadInches =
            plantRecord.avgSpreadInches > 0 ? (plantRecord.avgSpreadInches + num) / 2 : num;
          plantRecord.maxSpreadInches = Math.max(plantRecord.maxSpreadInches, num);
        }
      });
      if (plantRecord.isNative) {
        plantRecord.searchTerms.push(...['native', 'natives']);
      }
      galleryRecord = plantRecord;
    } else {
      keys = Object.keys(initGalleryRecord());
    }
    const currentKeys = Object.keys(galleryRecord);
    currentKeys.forEach((key) => {
      if (keys.indexOf(key) < 0) {
        delete galleryRecord[key];
      }
    });

    // search terms
    const terms = this.db.generateSearchTerms(galleryRecord.name, [
      ...galleryRecord.searchTerms,
      ...(galleryAltName ? this.db.generateSearchTerms(galleryAltName) : []),
      ...galleryRecord.categorySearch,
      ...[galleryRecord.collection],
      ...galleryRecord.catalogRecords,
      ...galleryRecord.galleryCollection.split('-'),
      galleryRecord.galleryCollection,
      galleryRecord.category,
      galleryRecord.id
    ]);
    galleryRecord.searchTerms = Array.from(new Set(terms)); // remove dupes
    return galleryRecord;
  }

  updateGalleryItem(record: GalleryRecord): Promise<GalleryRecord> {
    return this.db.update<GalleryRecord>(this.prepareGalleryItem(record));
  }

  deleteGalleryItem(record: GalleryRecord): Promise<void> {
    return this.db.delete<GalleryRecord>(record);
  }

  getGalleryRecord(record: GalleryRecord): Observable<GalleryRecord> {
    return this.db.doc$<GalleryRecord>(`gallery-records/${record.id}`, initGalleryRecord);
  }

  getGalleryItems(searchTerm: string = '', startAfter: any = '') {
    let queryFn;

    queryFn = (ref) => {
      if (searchTerm) {
        ref = ref.where('searchTerms', 'array-contains', searchTerm.toLowerCase());
      }

      ref = ref.orderBy('name', 'asc');
      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }
      ref = ref.limit(8);

      return ref;
    };
    return searchTerm
      ? this.db.queryGet$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        )
      : this.db.query$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        );
  }

  getGalleryItemsMissingPhotos(searchTerm: string = '', startAfter: any = '', limit = 15) {
    let queryFn;
    queryFn = (ref) => {
      ref = ref.where('photoCount', '==', 0);
      if (searchTerm) {
        ref = ref.where('searchTerms', 'array-contains', searchTerm.toLowerCase());
      }

      ref = ref.orderBy('name', 'asc');

      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }
      ref = ref.limit(limit);
      return ref;
    };
    return searchTerm
      ? this.db.queryGet$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        )
      : this.db.query$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        );
  }

  getLiveGalleryRecords(searchTerm: string = '', startAfter: any = '') {
    let queryFn;
    queryFn = (ref) => {
      ref = ref.where('hasPhotos', '==', true);
      if (searchTerm) {
        ref = ref.where('searchTerms', 'array-contains', searchTerm.toLowerCase());
      }
      ref = ref.orderBy('name', 'asc');
      if (startAfter) {
        ref = ref.startAfter(startAfter);
      }
      ref = ref.limit(3);

      return ref;
    };
    return searchTerm
      ? this.db.queryGet$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        )
      : this.db.query$<GalleryRecord | PlantGalleryRecord>(
          'gallery-records',
          queryFn,
          initGalleryRecord
        );
  }

  private numericQuantity(qty: string) {
    const badResult = NaN;
    let finalResult = badResult;
    // Resolve any unicode vulgar fractions
    const vulgarFractionsRegex =
      /(\u00BC|\u00BD|\u00BE|\u2150|\u2151|\u2152|\u2153|\u2154|\u2155|\u2156|\u2157|\u2158|\u2159|\u215A|\u215B|\u215C|\u215D|\u215E)/;
    const vulgarFractionsCharMap: any = {
      '\u00BC': ' 1/4',
      '\u00BD': ' 1/2',
      '\u00BE': ' 3/4',
      '\u2150': ' 1/7',
      '\u2151': ' 1/9',
      '\u2152': ' 1/10',
      '\u2153': ' 1/3',
      '\u2154': ' 2/3',
      '\u2155': ' 1/5',
      '\u2156': ' 2/5',
      '\u2157': ' 3/5',
      '\u2158': ' 4/5',
      '\u2159': ' 1/6',
      '\u215A': ' 5/6',
      '\u215B': ' 1/8',
      '\u215C': ' 3/8',
      '\u215D': ' 5/8',
      '\u215E': ' 7/8'
    };
    const sQty = ('' + qty)
      .replace(vulgarFractionsRegex, (m, vf) => {
        return vulgarFractionsCharMap[vf];
      })
      .trim();
    /**
     *                    Regex captures
     *
     *  +=====+====================+========================+
     *  |  #  |    Description     |        Example         |
     *  +=====+====================+========================+
     *  |  0  |  entire string     |  "2 2/3" from "2 2/3"  |
     *  +-----+--------------------+------------------------+
     *  |  1  |  the dash          |  "-" from "-2 2/3"     |
     *  +-----+--------------------+------------------------+
     *  |  2  |  the whole number  |  "2" from "2 2/3"      |
     *  |     |  - OR -            |                        |
     *  |     |  the numerator     |  "2" from "2/3"        |
     *  +-----+--------------------+------------------------+
     *  |  3  |  entire fraction   |  "2/3" from "2 2/3"    |
     *  |     |  - OR -            |                        |
     *  |     |  decimal portion   |  ".66" from "2.66"     |
     *  |     |  - OR -            |                        |
     *  |     |  denominator       |  "/3" from "2/3"       |
     *  +=====+====================+========================+
     *
     *  re.exec("1")       // [ "1",     "1", null,   null ]
     *  re.exec("1.23")    // [ "1.23",  "1", ".23",  null ]
     *  re.exec("1 2/3")   // [ "1 2/3", "1", " 2/3", " 2" ]
     *  re.exec("2/3")     // [ "2/3",   "2", "/3",   null ]
     *  re.exec("2 / 3")   // [ "2 / 3", "2", "/ 3",  null ]
     */
    const re = /^(-)?\s*(\d*)(\.\d+|(\s+\d*\s*)?\s*\/\s*\d+)?$/;
    const ar = re.exec(sQty);
    // If the regex fails, give up
    if (!ar) {
      return badResult;
    }
    // Store the capture groups so we don't have to access the array
    // elements over and over
    const dash = ar[1];
    const numberGroup1 = ar[2];
    const numberGroup2 = ar[3];
    // The regex can pass and still capture nothing in the relevant groups,
    // which means it failed for our purposes
    if (!numberGroup1 && !numberGroup2) {
      return badResult;
    }
    // Numerify capture group 1
    if (!numberGroup1 && numberGroup2 && numberGroup2.search(/^\./) !== -1) {
      finalResult = 0;
    } else {
      finalResult = parseInt(numberGroup1);
    }
    if (isNaN(finalResult)) {
      return badResult;
    }
    // If capture group 2 is null, then we're dealing with an integer
    // and there is nothing left to process
    if (!numberGroup2) {
      return finalResult * (dash === '-' ? -1 : 1);
    }
    if (numberGroup2.search(/^\./) !== -1) {
      // If first char is "." it's a decimal so just trim to 3 decimal places
      const numerator = parseFloat(numberGroup2);
      finalResult += Math.round(numerator * 1000) / 1000;
    } else if (numberGroup2.search(/^\s*\//) !== -1) {
      // If the first non-space char is "/" it's a pure fraction (e.g. "1/2")
      const numerator = parseInt(numberGroup1);
      const denominator = parseInt(numberGroup2.replace('/', ''));
      finalResult = Math.round((numerator * 1000) / denominator) / 1000;
    } else {
      // Otherwise it's a mixed fraction (e.g. "1 2/3")
      const fractionArray = numberGroup2.split('/');
      const a = fractionArray.map((v) => {
        return parseInt(v);
      });
      const numerator = a[0];
      const denominator = a[1];
      finalResult += Math.round((numerator * 1000) / denominator) / 1000;
    }
    return finalResult * (dash === '-' ? -1 : 1);
  }
}
