<ng-container *ngIf="infinite$ | async">
  <cdk-virtual-scroll-viewport
    *ngIf="docs.length > 0"
    [itemSize]="recordSize"
    orientation="{{ isHorizontal ? 'horizontal' : '' }}"
    (scrolledIndexChange)="advanceScroller($event)"
  >
    <div
      class="record"
      [ngStyle]="{
        width: isHorizontal ? recordSize + 'px' : 'auto',
        height: !isHorizontal ? recordSize + 'px' : 'auto'
      }"
      *cdkVirtualFor="let doc of docs; let i = index; trackBy: trackByIdx; templateCacheSize: 0"
    >
      <ng-container *ngTemplateOutlet="child; context: { doc: doc }"></ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
