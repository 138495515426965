<button
  size="tiny"
  status="danger"
  nbButton
  ghost
  (click)="canDelete ? deleteBoard() : prepareForDelete()"
>
  <nb-icon icon="trash-2-outline" status="danger"></nb-icon>
  <span *ngIf="!canDelete">Delete</span>
  <span *ngIf="canDelete">Confirm</span>
</button>
<button *ngIf="canDelete" size="tiny" status="basic" nbButton ghost (click)="cancel()">
  Cancel
</button>
