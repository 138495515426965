<div class="search-container">
  <nb-icon icon="search" status="info"></nb-icon>
  <input
    (keyup)="makeSearch()"
    [(ngModel)]="queryString"
    type="text"
    shape="semi-round"
    status="info"
    placeholder="name"
    nbInput
  />
</div>
