import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Home',
    icon: 'home-outline',
    link: '/pages/home',
    home: true
  },
  {
    title: 'Analytics',
    link: '/pages/analytics',
    icon: 'activity-outline'
  },
  {
    title: 'Catalog',
    icon: 'file-text-outline',
    children: [
      {
        title: 'Database',
        link: '/pages/catalog/database'
      },
      {
        title: 'Update',
        link: '/pages/catalog/update'
      },
      {
        title: 'Upload PDF',
        link: '/pages/catalog/pdf'
      }
    ]
  },
  {
    title: 'Design',
    icon: 'brush-outline',
    children: [
      {
        title: 'Search',
        link: '/pages/design/search'
      },
      {
        title: 'Manage Fulfillment',
        link: '/pages/design/manage'
      },
      {
        title: 'Open Full Designs',
        link: '/pages/design/manage-full'
      },
      {
        title: 'Closed Designs',
        link: '/pages/design/closed'
      },
      {
        title: 'Settings',
        link: '/pages/design/settings'
      }
    ]
  },
  {
    title: 'Gallery',
    icon: 'camera-outline',
    children: [
      {
        title: 'Manage',
        link: '/pages/photo-gallery/manage'
      }
    ]
  },
  {
    title: 'Inventory',
    icon: 'layers-outline',
    children: [
      {
        title: 'Popular In-Stock',
        link: '/pages/inventory/manage'
      },
      { title: 'Out of Stock', link: '/pages/inventory/oos' }
    ]
  },
  {
    title: 'Operations',
    icon: 'settings-outline',
    children: [
      {
        title: 'Notifications',
        link: '/pages/operations/notifications'
      }
    ]
  },
  {
    title: 'Retail',
    icon: 'people-outline',
    children: [
      {
        title: 'Feature Enablement',
        link: '/pages/retail/feature-enablement'
      },
      {
        title: 'Hours',
        link: '/pages/retail/hours/hours'
      },
      {
        title: 'Slides',
        link: '/pages/retail/slides'
      },
      {
        title: 'Specials',
        link: '/pages/retail/specials'
      },
      {
        title: 'Theme',
        link: '/pages/retail/theme'
      }
    ]
  },
  {
    title: 'Shop',
    icon: 'shopping-cart-outline',
    children: [
      {
        title: 'Search Orders',
        link: '/pages/shop/search'
      },
      {
        title: 'Delivery Order Fulfillment',
        link: '/pages/shop/deliveries'
      },
      {
        title: 'Gift Card Order Fulfillment',
        link: '/pages/shop/gift-cards'
      },
      {
        title: 'Delivery Rules',
        link: '/pages/shop/delivery-rules'
      },
      {
        title: 'Manage Line Items',
        link: '/pages/shop/line-items'
      },
      {
        title: 'Settings',
        link: '/pages/shop/settings'
      }
    ]
  },
  {
    title: 'Wholesale',
    icon: 'car-outline',
    children: [
      {
        title: 'Manage Wholesale Users',
        link: '/pages/wholesale/users'
      },
      {
        title: 'Registration Requests',
        link: '/pages/wholesale/registration'
      },
      {
        title: 'Cimato Hours',
        link: '/pages/retail/hours/wholesale_cimato'
      },
      {
        title: 'Hours',
        link: '/pages/retail/hours/wholesale'
      },
      {
        title: 'Updates',
        link: '/pages/wholesale/updates'
      }
    ]
  },
  {
    title: 'Settings',
    icon: 'settings-outline',
    children: [
      {
        title: 'Manage CMS Users',
        link: '/pages/settings/users'
      }
    ]
  }
];
